import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';
import { useUserData } from '../../providers/userData';
import { useFileUpload } from '../../providers/fileUpload';
import {
  ButtonChangeBackground,
  Input,
  Select,
  WrapperButtons,
  WrapperForm,
} from './styled';
import FileUpload from '../FileUpload';

export default function Form() {
  const { setUser } = useUserData();
  const [, setName, nameRef] = useState('');
  const [, setDate, dateRef] = useState('');
  const [, setCountry, countryRef] = useState('');
  const [, setHeight, heightRef] = useState('');
  const [, setWeight, weightRef] = useState('');
  const [, setPosition, positionRef] = useState('');
  const [newBackground, setNewBackground, newBackgroundRef] = useState('');
  const [, setCountryImg, countryImgRef] = useState('');
  const { isShown, setIsShown } = useFileUpload();

  useEffect(() => {
    setNewBackground(0);
  }, [setNewBackground]);

  const handleOpenUpload = (e) => {
    e.preventDefault();
    setIsShown(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const placeH = document.querySelectorAll('.placeholder');
    placeH.forEach((element) => {
      element.classList.add('hide');
    });
  };

  const HandleSubmitForm = () => {
    setUser({
      name: nameRef.current,
      date: dateRef.current,
      country: countryRef.current,
      countryImg: countryImgRef.current,
      height: heightRef.current,
      weight: weightRef.current,
      position: positionRef.current,
      background: newBackgroundRef.current,
    });
  };

  const handleChangeBackground = (e) => {
    e.preventDefault();
    if (newBackground === 0) {
      toast.info('Background alterado.');
      setNewBackground(1);
    } else if (newBackground === 1) {
      toast.info('Background alterado.');
      setNewBackground(2);
    } else if (newBackground === 2) {
      toast.info('Background alterado.');
      setNewBackground(3);
    } else if (newBackground === 3) {
      toast.info('Background alterado.');
      setNewBackground(4);
    } else if (newBackground === 4) {
      toast.info('Background alterado.');
      setNewBackground(5);
    } else if (newBackground === 5) {
      toast.info('Background alterado.');
      setNewBackground(0);
    }
    HandleSubmitForm();
  };

  const handleChangeName = (e) => {
    setName(e.target.value.toUpperCase());
  };

  const handleChangeDate = (e) => {
    const dateToFormat = e.target.value;
    const newDate = dateToFormat.replace(/(^|-)0+/g, '$1');
    setDate(newDate);
  };
  const handleChangeHeight = (e) => {
    setHeight(e.target.value);
  };
  const handleChangeCountry = (e) => {
    // eslint-disable-next-line no-shadow
    const country = e.target.value;
    setCountry(country.toUpperCase());
    if (country === 'bra') {
      setCountryImg('bra');
    }
    if (country === 'ger') {
      setCountryImg('ger');
    }
    if (country === 'usa') {
      setCountryImg('usa');
    }
    if (country === 'ksa') {
      setCountryImg('ksa');
    }
    if (country === 'arg') {
      setCountryImg('arg');
    }
    if (country === 'aus') {
      setCountryImg('aus');
    }
    if (country === 'bel') {
      setCountryImg('bel');
    }
    if (country === 'cmr') {
      setCountryImg('cmr');
    }
    if (country === 'can') {
      setCountryImg('can');
    }
    if (country === 'qat') {
      setCountryImg('qat');
    }
    if (country === 'kor') {
      setCountryImg('kor');
    }
    if (country === 'crc') {
      setCountryImg('crc');
    }
    if (country === 'cro') {
      setCountryImg('cro');
    }
    if (country === 'den') {
      setCountryImg('den');
    }
    if (country === 'ecu') {
      setCountryImg('ecu');
    }
    if (country === 'esp') {
      setCountryImg('esp');
    }
    if (country === 'fra') {
      setCountryImg('fra');
    }
    if (country === 'gha') {
      setCountryImg('gha');
    }
    if (country === 'ned') {
      setCountryImg('ned');
    }
    if (country === 'eng') {
      setCountryImg('eng');
    }
    if (country === 'fra') {
      setCountryImg('fra');
    }
    if (country === 'irn') {
      setCountryImg('irn');
    }
    if (country === 'jpn') {
      setCountryImg('jpn');
    }
    if (country === 'fra') {
      setCountryImg('fra');
    }
    if (country === 'mar') {
      setCountryImg('mar');
    }
    if (country === 'mex') {
      setCountryImg('mex');
    }
    if (country === 'wal') {
      setCountryImg('wal');
    }
    if (country === 'pol') {
      setCountryImg('pol');
    }
    if (country === 'por') {
      setCountryImg('por');
    }
    if (country === 'sen') {
      setCountryImg('sen');
    }
    if (country === 'srb') {
      setCountryImg('srb');
    }
    if (country === 'sui') {
      setCountryImg('sui');
    }
    if (country === 'tun') {
      setCountryImg('tun');
    }
    if (country === 'uru') {
      setCountryImg('uru');
    }
  };
  const handleChangeWeight = (e) => {
    setWeight(e.target.value);
  };
  const handleChangePosition = (e) => {
    setPosition(e.target.value);
  };

  return (
    <div>
      {isShown && (
        <div>
          <FileUpload />
        </div>
      )}

      <WrapperForm>
        <form action="/" onChange={HandleSubmitForm}>
          <button
            type="button"
            className="upload-foto-button"
            onClick={handleOpenUpload}
          />

          <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            type="text"
            name=""
            id=""
            placeholder="Cristiano Ronaldo"
            onChange={handleChangeName}
            className="blocked"
            maxLength="15"
          />

          <IMaskInput
            name="dateMask"
            mask="num"
            blocks={{
              num: {
                mask: '00-00-0000',
              },
            }}
            placeholder="5-2-1985"
            onChange={handleChangeDate}
            className="inputMask"
          />

          <Select
            defaultValue="default"
            name=""
            id=""
            onChange={handleChangeCountry}
            className="blocked"
          >
            <option value="default" disabled>
              País
            </option>
            <option value="bra">Brasil</option>
            <option value="ger">Alemanha</option>
            <option value="ksa">Arábia Saudita</option>
            <option value="arg">Argentina</option>
            <option value="aus">Austrália</option>
            <option value="bel">Bélgica</option>
            <option value="cmr">Camarões</option>
            <option value="can">Canadá</option>
            <option value="qat">Qatar</option>
            <option value="kor">Coréia do Sul</option>
            <option value="crc">Costa Rica</option>
            <option value="cro">Croácia</option>
            <option value="den">Dinamarca</option>
            <option value="ecu">Equador</option>
            <option value="esp">Espanha</option>
            <option value="usa">Estados Unidos</option>
            <option value="fra">França</option>
            <option value="gha">Gana</option>
            <option value="ned">Holanda</option>
            <option value="eng">Inglaterra</option>
            <option value="irn">Irã</option>
            <option value="jpn">Japão</option>
            <option value="mar">Marrocos</option>
            <option value="mex">México</option>
            <option value="wal">País de Gales</option>
            <option value="pol">Polônia</option>
            <option value="por">Portugal</option>
            <option value="sen">Senegal</option>
            <option value="srb">Sérvia</option>
            <option value="sui">Suíça</option>
            <option value="tun">Tunísia</option>
            <option value="uru">Uruguai</option>
          </Select>

          <div className="input-wrapper">
            <IMaskInput
              mask="heightm"
              blocks={{
                height: {
                  mask: '0,00',
                },
              }}
              placeholder="1,87m"
              onChange={handleChangeHeight}
              className="inputMask"
            />
            <IMaskInput
              mask="000kg"
              placeholder="87kg"
              onChange={handleChangeWeight}
              className="inputMask"
            />
          </div>

          <Select
            defaultValue="default"
            name=""
            id=""
            onChange={handleChangePosition}
            className="blocked"
          >
            <option value="default" disabled>
              Posição
            </option>
            <option value="goleiro">Goleiro</option>
            <option value="ataque">Ataque</option>
            <option value="centro">Centro</option>
            <option value="defesa">Defesa</option>
          </Select>

          <WrapperButtons>
            <ButtonChangeBackground
              className="buttonBackground"
              onClick={handleChangeBackground}
            >
              Background
            </ButtonChangeBackground>
          </WrapperButtons>
        </form>
      </WrapperForm>
    </div>
  );
}
