import styled from 'styled-components';

export const LockerDiv = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  top: 0;
  left: 0;

  img {
    width: 427px;
    height: 550px;
  }
`;

export const LockerImg = styled.img`
  width: 427px;
  height: 550px;
`;
