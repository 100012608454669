import styled from 'styled-components';
// import baseBkg from '../../images/Page-1.png';
import uploadImg from '../../images/upload.png';
import maskImg from '../../images/maskImg.png';

export const Wrapper = styled.div`
  .hide {
    display: none !important;
  }

  .uploadAdviceClass {
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
  }
  .container {
    height: 100%;
    width: 100%;
    background-attachment: fixed;
    position: fixed;
    left: 0;
    z-index: 100;
    padding-top: 160px;
    top: 0;
    background-color: #501f32aa;
  }

  .container-cropper {
    height: 80%;
    padding: 10px;
    text-align: center;
    border-bottom: 10px solid #8e1537;
    border-top: 10px solid #8e1537;
    background-color: #700a27;
  }

  .cropper {
    height: 80%;
    position: relative;
    /* -webkit-mask-image: url(${maskImg});
    mask-image: url(${maskImg});
    -webkit-mask-position: center;
    mask-position: top;
    mask-repeat: no-repeat;
    mask-mode: alpha;
    mask-type: alpha; */
  }

  .slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: 10px auto;
    width: 50%;
  }

  .container-buttons {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonEscolher {
    width: 200px;
    height: 52px;
    background: url(${uploadImg}) center no-repeat;
    background-size: cover;
    top: 35%;
    position: absolute;
  }

  .buttonConfimar {
    width: 136px;
    height: 52px;
    background: #d0bd5b;
    text-align: center;
    font-family: 'Segoe UI';
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    color: #8e1537;
    margin-top: 50px;
  }

  p {
    color: #fff;
    font-family: 'Segoe UI';
    font-weight: bold;
    margin-bottom: 5px;
  }
  a {
    color: #ddd9;
  }
`;
