import React from 'react';
import { toast } from 'react-toastify';
// import html2canvas from 'html2canvas';
import html2canvas from 'html2canvas';
import Loading from '../Loading';
import { WrapperButtons } from './styled';

import print from '../../images/print.png';
// import share from '../../images/share.svg';
import { useIsLoading } from '../../providers/loading';
import { usePicture } from '../../providers/picture';
// eslint-disable-next-line no-unused-vars
import history from '../../services/history';

export default function PrintMyFig() {
  const { isLoading, setIsLoading } = useIsLoading();
  const { setPicture } = usePicture();

  const setNewPicture = () => {
    const userImage = document.querySelector('.userImage');
    if (!userImage.getAttribute('src') > 0) {
      toast.error('Necessário adicionar uma imagem!');
    } else {
      const src = userImage.getAttribute('src');
      toast.warn('Gerando figurinha!');
      setPicture(src);
      setIsLoading(true);
      setTimeout(() => {
        const input = document.getElementById('image-render');
        const input2 = document.getElementById('image-render-final');

        html2canvas(input, {
          logging: true,
          letterRendering: 1,
          useCORS: true,
        }).then((canvas) => {
          localStorage.setItem(
            'wDv8yL6bOWP7zLOCD8Ppm57Vu6DIYNr4yQqVXYioPidK51SQ7K0qu1eBc7TshQw35N0sNJSmH0kX7b7sS2LeWDLEH6BK7bxSl0TILUzMbW0Xd4gkIfh63916a1b895e7',
            canvas.toDataURL()
          );
          canvas.toBlob(
            (blob) => {
              const previewUrl = window.URL.createObjectURL(blob);

              const anchor = document.createElement('a');
              anchor.download = 'image.jpeg';
              anchor.href = URL.createObjectURL(blob);
              // anchor.click();

              window.URL.revokeObjectURL(previewUrl);
            },
            'image/jpg',
            1
          );
          // const link = document.createElement('a');
          // link.download = 'filename.png';
          // link.href = canvas.toDataURL();
          // link.click();
          document.querySelector('body').appendChild(canvas);
        });

        html2canvas(input2, {
          logging: true,
          letterRendering: 1,
          useCORS: true,
        }).then((canvas) => {
          localStorage.setItem(
            'V3P9HIgE7wp0JmWNjGkmqnYoN7JIdM62qlUBnyWYI8JentNx6uDbrdULeyACuW6zML58D71IPICLH7KIOYG3nbpFQbNzPjQwxVLfS8QXlRpZGiM9Y1L63916a12c2c90',
            canvas.toDataURL()
          );
          canvas.toBlob(
            (blob) => {
              const previewUrl = window.URL.createObjectURL(blob);

              const anchor = document.createElement('a');
              anchor.download = 'image.jpeg';
              anchor.href = URL.createObjectURL(blob);
              // anchor.click();

              window.URL.revokeObjectURL(previewUrl);
            },
            'image/jpg',
            1
          );
          // const link = document.createElement('a');
          // link.download = 'filename.png';
          // link.href = canvas.toDataURL();
          // link.click();
          document.querySelector('body').appendChild(canvas);
        });
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });

          toast.success('Figurinha gerada com sucesso!');
          history.push('/checkout');
          setIsLoading(false);
        }, 2000);
      }, 3000);
    }
  };

  return (
    <WrapperButtons>
      <Loading isLoading={isLoading} />
      <button onClick={setNewPicture} className="imprimirBtn">
        <img src={print} alt="" />
      </button>
    </WrapperButtons>
  );
}
