import styled, { createGlobalStyle } from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';
import maskImg from '../images/maskImg.png';
import baseBkg from '../images/Page-1.png';

export default createGlobalStyle`

  canvas {
    /* -webkit-mask-image: url(${maskImg});
    mask-image: url(${maskImg});
    -webkit-mask-position: center;
    mask-position: center;
    mask-repeat: no-repeat; */
  }

  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }
  @font-face {
  font-family: 'Qatar2022 Arabic';
  src: local('Qatar2022 Arabic'), url('../src/assets/fonts/Qatar2022Arabic-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Qatar2022 Arabic';
  src: local('Qatar2022 Arabic'), url('../src/assets/fonts/Qatar2022Arabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Arabic';
  src: local('Graphik Arabic'), url('../src/assets/fonts/GraphikArabic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

img {
  pointer-events: none;
}

#final-img {
  border: 1px dotted #ccc;
}
#final-image {
  position: absolute;
}

  body {
    font-family: 'Segoe UI';
    /* background-color: ${colors.primaryDarkColor}; */
    background: url(${baseBkg}), linear-gradient(180deg, rgba(114, 7, 34, 1) 0%, rgba(39, 0, 21, 1) 100%);
    background-attachment: fixed;
    color: ${colors.primaryDarkColor};
    height: 100%;
  }

  html, body, #root {
    height: 100%;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  a {
    text-decoration: none;
    color: ${colors.primaryColor};
  }

  ul {
    list-style: none;
  }

  .hide {
    display: none !important;
  }

  /* body .Toastify .Toastify__toast-container .Toastify__toast--success {
    background-color: ${colors.sucessColor};
  } */
  canvas {
    display: none !important;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.section`
  display: flex;
  max-width: 1024px;
  width: 100%;
  margin: 30px auto;
  height: 600px;
  flex-direction: row-reverse;
  /* max-width: 360px;
  background-color: #fff;
  margin: 30px auto;
  padding: 30px;
  border-radius: 4px;
  box-shadow: inset 1px 1px 11px #00000058, 1px 1px 30px rgba(0, 0, 0, 0.5); */
  transition: all 0.3s ease;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 1023px),
    (max-width: 1024px) and (orientation: portrait) {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
  }
`;
