import React, { createContext, useContext, useState } from 'react';

export const FileUploadContext = createContext({});

export function FileUploadProvider(props) {
  const [isShown, setIsShown] = useState(false);

  // useEffect(() => {
  //   const userStorage = localStorage.getItem('user');
  //   if (userStorage) {
  //     setUser(JSON.parse(userStorage));
  //   } else {
  //     setUser({
  //       name: '',
  //     });
  //   }
  // });

  return (
    <FileUploadContext.Provider value={{ isShown, setIsShown }}>
      {props.children}
    </FileUploadContext.Provider>
  );
}

export const useFileUpload = () => useContext(FileUploadContext);
