import { Link } from 'react-router-dom';
import styled from 'styled-components';
import newFigBtn from '../../images/newFigBtn.svg';
import finishBtn from '../../images/finish.png';

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #700a27;
  border-top: 10px solid #8e1537;
  border-bottom: 10px solid #8e1537;
`;

export const Button = styled.button`
  width: 256px;
  height: 52px;
  width: 205px;
  height: 52px;
  border-radius: 0px 2px 2px 0px;
  background: url(${finishBtn}) center no-repeat;
  background-size: contain;
  margin: 10px auto 30px;
`;

export const Input = styled.input`
  height: 50px;
  margin: 0 auto 20px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  color: #8e1537;
  padding: 10px 16px;
  border-radius: 2px;
  max-width: 394px;
  width: 100%;
  border: none;
  transition: all 0.5s ease;
`;

export const P = styled.p`
  font-family: 'Segoe UI';
  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin: 0 auto 30px;

  @media only screen and (max-width: 1023px),
    (max-width: 1024px) and (orientation: portrait) {
    font-size: 18px;
    font-weight: normal;
    padding: 0 20px;
  }
`;

export const ButtonPayShare = styled.button`
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-direction: row;
  background-color: #ffffff2b;

  p {
    font-family: 'Segoe UI';
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 200px;
  }
`;

export const WrapperButtons = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  .shake-bottom:hover {
    -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
    animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }

  @-webkit-keyframes shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
      transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }
  @keyframes shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
      transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }

  button {
    transition: all 0.5s ease;
    border-radius: 2px;

    &:hover {
      box-shadow: 1px 1px 20px #ddd;
    }
  }
`;

export const FinalImagePic = styled.img`
  width: 427px;
  max-height: 100%;
  margin: 20px auto;
  max-width: 100%;
`;

export const StyledLink = styled(Link)`
  width: 275px;
  height: 63px;
  background: url(${newFigBtn}) center/contain no-repeat;
  left: 0;
  right: 0;
  margin: 80px auto 50px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledP = styled.p`
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
`;
export const StyledSpan = styled.span`
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #d0bd5b;
  margin-bottom: 50px;
`;
