import styled from 'styled-components';

export const FooterStyled = styled.footer`
  margin-top: 100px;
  padding-bottom: 20px;
  /* Animation */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 7));
    }
  }

  /*  Styling  */
  #logos-home-page .slider {
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  #logos-home-page .slider:before,
  #logos-home-page .slider:after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  #logos-home-page .slider:after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  #logos-home-page .slider:before {
    left: 0;
    top: 0;
  }
  #logos-home-page .slide-track {
    animation: scroll 20s linear infinite;
    display: flex;
    width: calc(250px * 7);
  }

  #logos-home-page div.slide {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .securePay {
    display: flex;
    margin: 100px auto;
    max-width: 100%;
  }
`;
