import React, { createContext, useContext, useState } from 'react';

export const IsLoadingContext = createContext({});

export function IsLoadingProvider(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IsLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </IsLoadingContext.Provider>
  );
}

export const useIsLoading = () => useContext(IsLoadingContext);
