import React, { useEffect } from 'react';

import Loading from '../../components/Loading';
import { useIsLoading } from '../../providers/loading';
import { Wrapper, StyledLink, Title } from './styled';

export default function Page404() {
  const { isLoading, setIsLoading } = useIsLoading();

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [setIsLoading]);

  return (
    <Wrapper>
      <Loading isLoading={isLoading} />

      <Title>Page 404</Title>
      <StyledLink to="/"> - HOME -</StyledLink>
    </Wrapper>
  );
}
