/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';

import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { generateDownload } from '../../utils/cropImage';
import { useFileUpload } from '../../providers/fileUpload';
import { Wrapper } from './styled';

import uploadAdvice from '../../images/uploadAdvice.png';

export default function FileUpload() {
  const inputRef = React.useRef();
  const { setIsShown } = useFileUpload();

  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        setImage(reader.result);
        document.querySelector('.buttonEscolher').classList.add('hide');
        document.querySelector('.uploadAdviceClass').classList.add('hide');
      });
    }
  };

  const handleAddPicture = () => {
    generateDownload(image, croppedArea);
    const render = document.getElementById('image-render');
    render.scrollIntoView({ top: 0, behavior: 'smooth' });

    setIsShown(false);
  };

  return (
    <Wrapper>
      <div className="container">
        <img src={uploadAdvice} alt="" className="uploadAdviceClass" />
        <div className="container-cropper">
          {image ? (
            <>
              <div className="cropper">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 4}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>

              <div className="slider">
                <Slider
                  color="warning"
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  // eslint-disable-next-line no-shadow
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="container-buttons">
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onSelectFile}
            style={{ display: 'none' }}
          />
          <button onClick={triggerFileSelectPopup} className="buttonEscolher" />
          <button onClick={handleAddPicture} className="buttonConfimar">
            Confirmar
          </button>
        </div>
      </div>
    </Wrapper>
  );
}
