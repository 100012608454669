import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { Container, Main } from '../../styles/GlobalStyles';
// import { Title } from './styled';
// import * as exampleActions from '../../store/modules/example/actions';
import Form from '../../components/Form';
import ImageRender from '../../components/ImageRender';
import Ads from '../../components/Ads';
import Loading from '../../components/Loading';
import PrintMyFig from '../../components/Print';

import { useIsLoading } from '../../providers/loading';
import FinalImage from '../../components/FinalImage';

export default function Login() {
  // const dispatch = useDispatch();

  // function handleClick(e) {
  //   e.preventDefault();

  //   dispatch(exampleActions.clicaBotaoRequest());
  // }
  const { isLoading, setIsLoading } = useIsLoading();
  // localStorage.clear();

  useEffect(() => {
    toast.dismiss();
    // const finalDIv = document.getElementById('image-render-final-wrapper');
    // finalDIv.style.top = '-9999999px';
    // finalDIv.style.left = '-9999999px';
    document.addEventListener('keyup', (e) => {
      if (e.key === 'PrintScreen') {
        navigator.clipboard.writeText('');
        alert('Screenshots disabled!');
      }
    });

    /** TO DISABLE PRINTS WHIT CTRL+P * */
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'p') {
        alert('This section is not allowed to print or export to PDF');
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    document.addEventListener('contextmenu', (event) => event.preventDefault());
    const img = document.querySelectorAll('img');
    img.forEach((element) => {
      element.setAttribute('draggable', false);
    });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      localStorage.clear();
    }, 2000);
  }, [setIsLoading]);

  return (
    <Main>
      <Loading isLoading={isLoading} />
      <Ads />
      <Container>
        <Form />
        <ImageRender />
      </Container>
      <PrintMyFig />
      <FinalImage />
    </Main>
  );
}
