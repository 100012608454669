import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const WrapperButtons = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 200px;

  button {
    transition: all 0.5s ease;
    border-radius: 2px;

    &:hover {
      box-shadow: 1px 1px 20px #ddd;
    }
  }
`;
export const StyledLink = styled(Link)`
  transition: all 0.5s ease;
  border-radius: 2px;

  &:hover {
    box-shadow: 1px 1px 20px #ddd;
  }
`;
