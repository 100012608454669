import styled from 'styled-components';

export const HeaderTop = styled.header`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 1023px),
    (max-width: 1024px) and (orientation: portrait) {
    img {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 500px),
    (max-width: 500px) and (orientation: portrait) {
    flex-direction: column;
  }
`;

export const Advice = styled.img`
  margin: 50px auto;
  max-width: 507px;
  width: 100%;
  display: flex;
`;

export const Title = styled.h1`
  color: #fff;
  font-family: Qatar2022 Arabic;
  font-weight: bold;
  font-size: 22px;
`;
