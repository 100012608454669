import React, { createContext, useContext, useState } from 'react';

export const UserDataContext = createContext({});

export function UserDataProvider(props) {
  const [user, setUser] = useState({
    name: '',
    date: '',
    country: '',
    countryImg: 0,
    weight: '',
    height: '',
    position: '',
    background: 0,
    picture: '',
  });

  // useEffect(() => {
  //   const userStorage = localStorage.getItem('user');
  //   if (userStorage) {
  //     setUser(JSON.parse(userStorage));
  //   } else {
  //     setUser({
  //       name: '',
  //     });
  //   }
  // });

  return (
    <UserDataContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserDataContext.Provider>
  );
}

export const useUserData = () => useContext(UserDataContext);
