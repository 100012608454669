import styled from 'styled-components';
import arrowSelect from '../../images/arrowSelect.svg';
import buttonUpload from '../../images/buttonUpload.svg';
import bkgBackgroundButton from '../../images/bkgBackgroundButton.svg';

export const WrapperForm = styled.div`
  width: 100%;
  text-align: center;
  display: block !important;

  .popUp {
    position: absolute;
    background-color: #ccc;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 2px;
    display: block;
    top: -50px;
    left: 0;
    opacity: 0.5;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 394px;
    width: 100%;
    margin: 0 auto;
  }
  .inputMask {
    height: 50px;
    margin-bottom: 20px;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 22px;
    text-align: left;
    color: #8e1537 !important;
    padding: 10px 16px;
    border-radius: 2px;
    max-width: 394px;
    width: 100%;
    border: none;
  }
  .upload-foto-button {
    background-image: url(${buttonUpload});
    width: 200px;
    height: 52px;
    margin-bottom: 25px;
  }

  @keyframes shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
      transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }
`;

export const Input = styled.input`
  height: 50px;
  margin-bottom: 20px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  color: #8e1537;
  padding: 10px 16px;
  border-radius: 2px;
  max-width: 394px;
  width: 100%;
  border: none;
  transition: all 0.5s ease;
`;

export const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  max-width: 394px;
  width: 100%;
  height: 50px;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 0 16px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  /* color: #bfbfbf; */
  color: #8e1537;
  background: url(${arrowSelect}) no-repeat white;
  background-position-x: 95%;
  background-position-y: center;

  option {
    max-width: 394px;
    width: 100%;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 439px),
    (max-width: 439px) and (orientation: portrait) {
    flex-direction: column;
    gap: 25px;
    justify-content: center;
  }
`;

export const ButtonChangeBackground = styled.button`
  max-width: 180px;
  width: 100%;
  height: 52px;
  border-radius: 2px;
  background: url(${bkgBackgroundButton}) no-repeat, #fff;
  background-position: 7px;
  font-family: 'Segoe UI';
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  padding-left: 43px;
  padding-bottom: 3px;
  color: #8e1537;

  @media only screen and (max-width: 439px),
    (max-width: 439px) and (orientation: portrait) {
    max-width: 100%;
    width: 100%;
  }
`;
