import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  font-size: 2em;
  color: #ddd;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 100px auto;
`;

export const StyledLink = styled(Link)`
  transition: all 0.5s ease;
  border-radius: 2px;
  margin-top: 50px;
  font-family: 'Segoe UI';
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 20px #ddd;

  &:hover {
    text-shadow: 1px 1px 20px #ddd;
  }
`;
