import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: #700a27;
  margin: 30px auto;
  border-top: 10px solid #8e1537;
  border-bottom: 10px solid #8e1537;

  p {
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 223px;
    left: 0;
    right: 118px;
    margin: 0 auto;
  }

  a {
    color: #d0bd5b;
  }

  .mob {
    display: none;
  }
  .web {
    display: block;
  }

  @media only screen and (max-width: 1023px),
    (max-width: 1024px) and (orientation: portrait) {
    .web {
      display: none;
    }
    .mob {
      display: block;
      max-width: 100%;
    }
    p {
      font-family: 'Segoe UI';
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 507px;
      left: 0;
      right: 118px;
      margin: 0 auto;
    }
  }
`;
