import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import history from './services/history';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Routes from './routes';
import { UserDataProvider } from './providers/userData';
import { FileUploadProvider } from './providers/fileUpload';
import { IsLoadingProvider } from './providers/loading';
import { PictureProvider } from './providers/picture';
import Footer from './components/Footer';
import { IsPaidProvider } from './providers/paid';

function App() {
  return (
    <PictureProvider>
      <IsLoadingProvider>
        <IsPaidProvider>
          <FileUploadProvider>
            <UserDataProvider>
              <ToastContainer autoClose={2000} className="toast-container" />
              <Router history={history}>
                <Header />
                <Routes />
                <GlobalStyles />
                <Footer />
              </Router>
            </UserDataProvider>
          </FileUploadProvider>
        </IsPaidProvider>
      </IsLoadingProvider>
    </PictureProvider>
  );
}

export default App;
