import React, { createContext, useContext, useState } from 'react';

export const IsPaidContext = createContext({});

export function IsPaidProvider(props) {
  const [isPaid, setIsPaid] = useState(true);

  return (
    <IsPaidContext.Provider value={{ isPaid, setIsPaid }}>
      {props.children}
    </IsPaidContext.Provider>
  );
}

export const useIsPaid = () => useContext(IsPaidContext);
