import React from 'react';
import PropTypes from 'prop-types';
import { LockerDiv, LockerImg } from './styled';

import lockImg from '../../images/LOCK.png';

export default function Locker({ isPaid }) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isPaid) return <></>;
  return (
    <LockerDiv>
      <LockerImg crossOrigin="" alt="" src={lockImg} />
    </LockerDiv>
  );
}

Locker.defaultProps = {
  isPaid: false,
};

Locker.propTypes = {
  isPaid: PropTypes.bool,
};
