/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-inner-declarations */
/* eslint-disable new-cap */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { Container } from '../../styles/GlobalStyles';
import Loading from '../../components/Loading';
import { useIsLoading } from '../../providers/loading';
import { usePicture } from '../../providers/picture';
import history from '../../services/history';
import print from '../../images/print.png';
import {
  Main,
  Input,
  StyledLink,
  Button,
  FinalImagePic,
  StyledP,
  StyledSpan,
} from './styled';
import { useIsPaid } from '../../providers/paid';
import paidImg from '../../images/paidImg.svg';
import paidImg2 from '../../images/paidImg2.svg';

export default function Checkout() {
  const { search } = useLocation();
  const collectionStatus = new URLSearchParams(search).get('collection_status');
  const paymentId = new URLSearchParams(search).get('payment_id');
  const merchantOrderId = new URLSearchParams(search).get('merchant_order_id');
  const { setIsLoading, isLoading } = useIsLoading();
  const [, setNewPicture] = useState();
  const { picture } = usePicture();
  const { isPaid, setIsPaid } = useIsPaid();
  const [file, setFile] = useState();
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [freePicU, setFreePicU] = useState();
  const [paidPicU, setPaidPicU] = useState();
  const [userEmailU, setuserEmailU] = useState();
  const [userNameU, setuserNameU] = useState();

  const campos = {
    nome,
    email,
    mensagem: `Obrigado por sua compra ${nome}!`,
    anexo: new File([file], `figurinha_de_${nome}.jpeg`),
    collectionStatus,
    paymentId,
    merchantOrderId,
  };
  const paidPic = localStorage.getItem(
    'V3P9HIgE7wp0JmWNjGkmqnYoN7JIdM62qlUBnyWYI8JentNx6uDbrdULeyACuW6zML58D71IPICLH7KIOYG3nbpFQbNzPjQwxVLfS8QXlRpZGiM9Y1L63916a12c2c90'
  );
  const freePic = localStorage.getItem(
    'wDv8yL6bOWP7zLOCD8Ppm57Vu6DIYNr4yQqVXYioPidK51SQ7K0qu1eBc7TshQw35N0sNJSmH0kX7b7sS2LeWDLEH6BK7bxSl0TILUzMbW0Xd4gkIfh63916a1b895e7'
  );

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function send() {
    const formData = new FormData();
    Object.keys(campos).forEach((key) => formData.append(key, campos[key]));
    axios
      .post('https://send-mail.onrender.com/send', formData, {
        headers: {
          // eslint-disable-next-line no-underscore-dangle
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((response) => {
        const dataJson = response.data;
        const { accepted } = dataJson;
        if (accepted) {
          toast.dismiss();
          toast.success(`Figurinha enviada para ${accepted}`);
          setIsLoading(false);
        } else {
          toast.error('Ocorreu um erro ao enviar.');
          setIsLoading(false);
          // eslint-disable-next-line no-alert
          const newEmail = prompt('Inserir novo email');
          localStorage.setItem('userEmail', newEmail);
          send(campos);
        }
      });
  }

  function blobToFile(theBlob, fileName) {
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    // eslint-disable-next-line no-param-reassign
    theBlob.lastModifiedDate = new Date();
    // eslint-disable-next-line no-param-reassign
    theBlob.name = fileName;
    return theBlob;
  }

  function handleInputChange(event) {
    if (event.target.name === 'nome') {
      setuserNameU(event.target.value);
    }
    if (event.target.name === 'email') {
      setuserEmailU(event.target.value);
    }
  }

  const MpPaymentHandler = async (cookies) => {
    const response = await axios.post(
      'https://mp-checkout.onrender.com/payment/new',
      {
        cookies,
      }
    );
    // console.log(response);
    window.location.href = response.data.init_point;
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    localStorage.setItem('639169b573071', userNameU);
    localStorage.setItem('639169bda2ad2', userEmailU);
    toast.loading('Abrindo tela de pagamento...');
    toast.loading('Ao finalizar aguarde para voltar ao site...');
    setTimeout(() => {
      if (!paidPic) {
        toast.error('Ocorreu um erro!');
        history.push('/');
        toast.dismiss();
        toast.error('Dados inválidos!');
      } else {
        MpPaymentHandler(1);
      }
    }, 5000);

    // send(campos);
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key === 'p') {
        // eslint-disable-next-line no-alert
        alert('This section is not allowed to print or export to PDF');
        e.cancelBubble = true;
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    document.addEventListener('contextmenu', (event) => event.preventDefault());
    const img = document.querySelectorAll('img');
    img.forEach((element) => {
      element.setAttribute('draggable', false);
    });
    if (picture) {
      setNewPicture(picture);
    }
    // if (!picture) {
    //   history.push('/');
    // }
    if (collectionStatus === 'approved') {
      setIsPaid(true);
      toast.loading('Enviando sua figurinha por email! Aguarde!');
      var blob = dataURLtoBlob(paidPic);
      var myFig = blobToFile(blob, `figurinha_de_${nome}.jpeg`);
      setFile(myFig);
    }
    if (collectionStatus === 'rejected') {
      setIsPaid(false);
      toast.error('Pagamento negado!');
    }
    if (collectionStatus === 'null') {
      setIsPaid(false);
      toast.warn('Refazer a compra!');
    }
    if (paidPic) {
      setPaidPicU(paidPic);
      const userEmail = localStorage.getItem('639169bda2ad2');
      const userName = localStorage.getItem('639169b573071');
      // console.log(isPaid, userEmail, userName);
      setEmail(userEmail);
      setNome(userName);
      setTimeout(() => {
        const input = document.getElementById('final-img');
        if (input) {
          html2canvas(input, {
            logging: true,
            letterRendering: 1,
            useCORS: true,
          }).then((canvas) => {
            const imgWidth = 49;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgData = canvas.toDataURL('img/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 4, 4, imgWidth, imgHeight);
            pdf.addImage(imgData, 'PNG', 80, 4, imgWidth, imgHeight);
            pdf.addImage(imgData, 'PNG', 4, 80, imgWidth, imgHeight);
            pdf.addImage(imgData, 'PNG', 80, 80, imgWidth, imgHeight);
            const pdfBlob = pdf.output('blob');
            var myFigPdf = blobToFile(pdfBlob, `figurinha_de_${nome}.pdf`);
            // setFile(myFigPdf);
          });
        }

        // eslint-disable-next-line no-shadow
        const userEmail = localStorage.getItem('639169bda2ad2');
        // eslint-disable-next-line no-shadow
        const userName = localStorage.getItem('639169b573071');
        // console.log(isPaid, userEmail, userName);
        setEmail(userEmail);
        setNome(userName);
      }, 4000);
    }
    if (freePic) {
      setFreePicU(freePic);
    }
  }, [collectionStatus, freePic, isPaid, nome, paidPic, picture, setIsPaid]);

  useEffect(() => {
    if (isPaid === true) {
      setIsLoading(true);

      setTimeout(() => {
        const input = document.getElementById('final-img');
        html2canvas(input, {
          logging: true,
          letterRendering: 1,
          useCORS: true,
        }).then((canvas) => {
          const imgWidth = 49;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL('img/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 4, 4, imgWidth, imgHeight);
          pdf.addImage(imgData, 'PNG', 80, 4, imgWidth, imgHeight);
          pdf.addImage(imgData, 'PNG', 4, 80, imgWidth, imgHeight);
          pdf.addImage(imgData, 'PNG', 80, 80, imgWidth, imgHeight);
          const pdfBlob = pdf.output('blob');
          var myFigPdf = blobToFile(pdfBlob, `figurinha_de_${nome}.pdf`);
          setFile(myFigPdf);
        });
        const userEmail = localStorage.getItem('639169bda2ad2');
        const userName = localStorage.getItem('639169b573071');
        // console.log(isPaid, userEmail, userName);
        setEmail(userEmail);
        setNome(userName);

        setTimeout(() => {
          // console.log(campos);
          send(campos);
        }, 3000);
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid]);

  const sharePic = () => {
    const input = document.getElementById('image-render');
    htmlToImage
      .toJpeg(input)
      // eslint-disable-next-line func-names
      .then(function (dataUrl) {
        const img = new Image();
        img.src = dataUrl;
        setFile(dataUrl);
        // document.body.appendChild(img);
        const anchor = document.createElement('a');
        anchor.download = 'image.jpeg';
        anchor.href = img.src;
        anchor.click();
      })
      .catch((err) => {
        // console.log(err);
      });
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      canvas.toBlob(
        (blob) => {
          const previewUrl = window.URL.createObjectURL(blob);

          const anchor = document.createElement('a');
          anchor.download = 'image.jpeg';
          anchor.href = URL.createObjectURL(blob);
          // anchor.click();

          window.URL.revokeObjectURL(previewUrl);
        },
        'image/jpg',
        1
      );
      // const link = document.createElement('a');
      // link.download = 'filename.png';
      // link.href = canvas.toDataURL();
      // link.click();
      document.querySelector('body').appendChild(canvas);
    });
  };

  const saveMyFig = () => {
    const input = document.getElementById('final-img');
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 49;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 4, 4, imgWidth, imgHeight);
      pdf.addImage(imgData, 'PNG', 80, 4, imgWidth, imgHeight);
      pdf.addImage(imgData, 'PNG', 4, 80, imgWidth, imgHeight);
      pdf.addImage(imgData, 'PNG', 80, 80, imgWidth, imgHeight);
      canvas.toBlob(
        (blob) => {
          const previewUrl = window.URL.createObjectURL(blob);

          const anchor = document.createElement('a');
          anchor.download = 'image.jpeg';
          anchor.href = URL.createObjectURL(blob);
          anchor.click();

          window.URL.revokeObjectURL(previewUrl);
        },
        'image/png',
        0.66
      );
      pdf.save('minha-figurinha.pdf');
    });
  };

  const newFig = (e) => {
    e.preventDefault();
    history.push('/');
    toast.info('Redirecionando para tela inicial!');
    toast.dismiss();
    setIsPaid(false);
    setTimeout(() => {
      localStorage.clear();
      setIsPaid(false);
    }, 2000);
  };

  if (isPaid) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Loading isLoading={isLoading} />
        <img
          src={paidImg}
          alt=""
          style={{
            margin: '80px auto',
            maxWidth: '310px',
            width: '100%',
            left: '0',
            right: '0',
          }}
        />
        <Main>
          <FinalImagePic src={paidPicU} id="final-img" />
          <img
            src={paidImg2}
            alt=""
            style={{
              margin: '40px auto',
              maxWidth: '440px',
              width: '100%',
              left: '0',
              right: '0',
            }}
          />
          <button
            onClick={saveMyFig}
            className="imprimirBtn"
            style={{ marginBottom: '25px' }}
          >
            <img src={print} alt="" />
          </button>
          <StyledP>O arquivo PDF também foi enviado para o email:</StyledP>
          <StyledSpan>{email}</StyledSpan>
        </Main>
        <StyledLink onClick={(e) => newFig(e)} to="/" />
      </div>
    );
  }
  return (
    <Main>
      <Loading isLoading={isLoading} />
      <Container style={{ margin: '0 auto' }}>
        <FinalImagePic src={freePicU} />
      </Container>

      <div className="container">
        <form
          onSubmit={handleFormSubmit}
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Input
            type="text"
            id="nome"
            name="nome"
            placeholder="Seu nome"
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleInputChange}
            required
          />
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Seu email"
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleInputChange}
            required
          />
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Confirme seu email"
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleInputChange}
            required
          />
          <Button type="submit" />
        </form>
      </div>
    </Main>
  );
}
