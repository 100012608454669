import React from 'react';
import { Container } from './styled';
import instructions from '../../images/instructions.svg';
import instructionsMob from '../../images/instructions-mob.svg';

export default function Ads() {
  return (
    <Container>
      <img src={instructions} alt="instructions to make a" className="web" />
      <p>
        <a
          href="https://erase.bg"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          erase.bg
        </a>
      </p>
      <img src={instructionsMob} alt="instructions to make a" className="mob" />
    </Container>
  );
}
