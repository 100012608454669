import styled from 'styled-components';
import { Link } from 'react-router-dom';
import placeholder from '../../images/placeholder.png';
import backTitle from '../../images/backTitle.svg';
import maskImg from '../../images/maskImg.png';

export const WrapperButtons = styled.div`
  margin: 0 auto;
  display: flex;
  gap: 20px;
  max-width: 200px;
  .shake-bottom:hover {
    -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
      both;
    animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }

  @keyframes shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
      transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
      transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }

  button {
    transition: all 0.5s ease;
    border-radius: 2px;

    &:hover {
      box-shadow: 1px 1px 20px #ddd;
    }
  }
`;
export const StyledLink = styled(Link)`
  transition: all 0.5s ease;
  border-radius: 2px;

  &:hover {
    box-shadow: 1px 1px 20px #ddd;
  }
`;

export const ImageRenderWrapper = styled.div`
  position: absolute;
  top: -99999px;
  left: -99999px;
  /* left: 0 !important; */
  /* top: 0 !important; */
  height: 550px;
  border: solid 1px black;

  @media only screen and (max-width: 1023px),
    (max-width: 1024px) and (orientation: portrait) {
    transform: scale(0.9);
    left: 0;
  }

  .hide {
    display: none !important;
  }

  .placeholder {
    position: absolute;
    z-index: 100;
  }

  .country-img {
    position: absolute;
    width: 60px;
    height: 61px;
    top: 70px;
    left: 308.7px;
  }

  .nameUser {
    font-family: Qatar2022 Arabic;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    width: 320px;
    color: #92133a;
    position: absolute;
    left: 53px;
    top: 436px;
    z-index: 30;
  }

  .dateUser {
    font-family: Qatar2022 Arabic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #e7e6dd;
    position: absolute;
    left: 172px;
    top: 473px;
    z-index: 30;
    width: 86px;
  }

  .heightUser {
    font-family: 'Qatar2022 Arabic';
    font-weight: normal;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 306px;
    z-index: 10;
    top: 204px;
  }

  .weightUser {
    font-family: 'Qatar2022 Arabic';
    font-weight: normal;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 353px;
    top: 204px;
    z-index: 10;
  }

  .positionWrapper {
    position: absolute;
    top: 156px;
    left: 66px;
    width: 41.95px;
    height: 66.18px;
  }

  .positionWrapper .positionBackground {
    width: 100%;
  }

  .country p {
    font-family: Qatar2022 Arabic;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #92133a;
    position: absolute;
    left: 322.7px;
    top: 54.3px;
  }

  .userImage,
  .userImageFinal {
    position: absolute;
    left: 77px;
    top: 80px;
    z-index: 0;
    max-width: 273.57px;
    max-height: 381.17px;
    width: 100%;
    height: 100%;
    border: none !important;
    /* -webkit-mask-image: url(${maskImg});
    mask-image: url(${maskImg});
    -webkit-mask-position: top;
    mask-position: top;
    mask-repeat: no-repeat;
    mask-mode: alpha;
    mask-type: alpha; */
    /* clip-path: polygon(
      1% 100%,
      99% 100%,
      96% 49%,
      76% 42%,
      65% 37%,
      66% 11%,
      64% 4%,
      46% 1%,
      29% 5%,
      26% 12%,
      27% 37%,
      14% 43%,
      1% 47%
    ); */
  }

  .imageToRender::before {
    content: '';
    position: absolute;
    background: url(${backTitle}) center no-repeat;
    width: 340px;
    height: 70px;
    top: 424px;
    z-index: 30;
    left: 44px;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 40;
  background: url(${placeholder}) center no-repeat;
  background-size: contain;
`;
