import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Checkout from '../pages/Checkout';
import About from '../pages/About';

export default function Routes() {
  return (
    <Switch>
      <MyRoute exact path="/" component={Login} />
      {/* <MyRoute exact path="/" component={Login} /> */}
      <MyRoute path="/checkout" component={Checkout} />
      <MyRoute path="/about" component={About} />
      <MyRoute path="*" component={Page404} />
    </Switch>
  );
}
