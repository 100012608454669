/* eslint-disable func-names */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-multi-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-inner-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from 'react';
import { toast } from 'react-toastify';
import { useUserData } from '../../providers/userData';

// Images
import positionAttack from '../../images/position-attack.svg';
import positionCenter from '../../images/position-center.svg';
import positionDefense from '../../images/position-defense.svg';
import positionGoal from '../../images/position-goal.svg';

// Backgrounds
import bkg1 from '../../images/bkg/userBkg1.svg';
import bkg2 from '../../images/bkg/userBkg2.svg';
import bkg3 from '../../images/bkg/userBkg3.svg';
import bkg4 from '../../images/bkg/userBkg4.svg';
import bkg5 from '../../images/bkg/userBkg5.svg';
import bkg6 from '../../images/bkg/userBkg6.svg';

// Flags
import flag_ARG from '../../images/flags/flag_ARG.svg';
import flag_AUS from '../../images/flags/flag_AUS.svg';
import flag_BEL from '../../images/flags/flag_BEL.svg';
import flag_BRA from '../../images/flags/flag_BRA.svg';
import flag_CAN from '../../images/flags/flag_CAN.svg';
import flag_CMR from '../../images/flags/flag_CMR.svg';
import flag_CRC from '../../images/flags/flag_CRC.svg';
import flag_CRO from '../../images/flags/flag_CRO.svg';
import flag_DEN from '../../images/flags/flag_DEN.svg';
import flag_ECU from '../../images/flags/flag_ECU.svg';
import flag_ENG from '../../images/flags/flag_ENG.svg';
import flag_ESP from '../../images/flags/flag_ESP.svg';
import flag_FRA from '../../images/flags/flag_FRA.svg';
import flag_GER from '../../images/flags/flag_GER.svg';
import flag_GHA from '../../images/flags/flag_GHA.svg';
import flag_IRN from '../../images/flags/flag_IRN.svg';
import flag_JPN from '../../images/flags/flag_JPN.svg';
import flag_KOR from '../../images/flags/flag_KOR.svg';
import flag_KSA from '../../images/flags/flag_KSA.svg';
import flag_MAR from '../../images/flags/flag_MAR.svg';
import flag_MEX from '../../images/flags/flag_MEX.svg';
import flag_NED from '../../images/flags/flag_NED.svg';
import flag_POL from '../../images/flags/flag_POL.svg';
import flag_POR from '../../images/flags/flag_POR.svg';
import flag_QAT from '../../images/flags/flag_QAT.svg';
import flag_SEN from '../../images/flags/flag_SEN.svg';
import flag_SRB from '../../images/flags/flag_SRB.svg';
import flag_SUI from '../../images/flags/flag_SUI.svg';
import flag_TUN from '../../images/flags/flag_TUN.svg';
import flag_URU from '../../images/flags/flag_URU.svg';
import flag_USA from '../../images/flags/flag_USA.svg';
import flag_WAL from '../../images/flags/flag_WAL.svg';

import placeholder from '../../images/placeholder.png';
// Images

import { ImageRenderWrapper } from './styled';
import Locker from '../Locker';
import { useIsPaid } from '../../providers/paid';

export default function ImageRender({
  newName,
  newDate,
  newCountry,
  newCountryImg,
  newHeight,
  newWeight,
  newPosition,
  newBackground,
  userImage,
}) {
  const { user } = useUserData();
  const { isPaid } = useIsPaid();
  newName = user.name;
  newDate = user.date;
  newCountry = user.country;
  newCountryImg = user.countryImg;
  newHeight = user.height;
  newWeight = user.weight;
  newPosition = user.position;
  newBackground = user.background;

  let userBkg;
  let userCountryImg;

  if (newBackground === 0) {
    userBkg = bkg4;
  } else if (newBackground === 1) {
    userBkg = bkg3;
  } else if (newBackground === 2) {
    userBkg = bkg2;
  } else if (newBackground === 3) {
    userBkg = bkg1;
  } else if (newBackground === 4) {
    userBkg = bkg5;
  } else if (newBackground === 5) {
    userBkg = bkg6;
  }

  if (newCountryImg === 'bra') {
    userCountryImg = flag_BRA;
  } else if (newCountryImg === 'usa') {
    userCountryImg = flag_USA;
  } else if (newCountryImg === 'ksa') {
    userCountryImg = flag_KSA;
  } else if (newCountryImg === 'arg') {
    userCountryImg = flag_ARG;
  } else if (newCountryImg === 'aus') {
    userCountryImg = flag_AUS;
  } else if (newCountryImg === 'bel') {
    userCountryImg = flag_BEL;
  } else if (newCountryImg === 'cmr') {
    userCountryImg = flag_CMR;
  } else if (newCountryImg === 'can') {
    userCountryImg = flag_CAN;
  } else if (newCountryImg === 'qat') {
    userCountryImg = flag_QAT;
  } else if (newCountryImg === 'kor') {
    userCountryImg = flag_KOR;
  } else if (newCountryImg === 'crc') {
    userCountryImg = flag_CRC;
  } else if (newCountryImg === 'cro') {
    userCountryImg = flag_CRO;
  } else if (newCountryImg === 'den') {
    userCountryImg = flag_DEN;
  } else if (newCountryImg === 'ecu') {
    userCountryImg = flag_ECU;
  } else if (newCountryImg === 'esp') {
    userCountryImg = flag_ESP;
  } else if (newCountryImg === 'fra') {
    userCountryImg = flag_FRA;
  } else if (newCountryImg === 'gha') {
    userCountryImg = flag_GHA;
  } else if (newCountryImg === 'ned') {
    userCountryImg = flag_NED;
  } else if (newCountryImg === 'eng') {
    userCountryImg = flag_ENG;
  } else if (newCountryImg === 'irn') {
    userCountryImg = flag_IRN;
  } else if (newCountryImg === 'jpn') {
    userCountryImg = flag_JPN;
  } else if (newCountryImg === 'mar') {
    userCountryImg = flag_MAR;
  } else if (newCountryImg === 'mex') {
    userCountryImg = flag_MEX;
  } else if (newCountryImg === 'wal') {
    userCountryImg = flag_WAL;
  } else if (newCountryImg === 'pol') {
    userCountryImg = flag_POL;
  } else if (newCountryImg === 'por') {
    userCountryImg = flag_POR;
  } else if (newCountryImg === 'sen') {
    userCountryImg = flag_SEN;
  } else if (newCountryImg === 'srb') {
    userCountryImg = flag_SRB;
  } else if (newCountryImg === 'sui') {
    userCountryImg = flag_SUI;
  } else if (newCountryImg === 'tun') {
    userCountryImg = flag_TUN;
  } else if (newCountryImg === 'uru') {
    userCountryImg = flag_URU;
  } else if (newCountryImg === 'ger') {
    userCountryImg = flag_GER;
  }

  if (!isPaid) {
    const render = document.getElementById('image-render');
    const isMobile = navigator.userAgentData.mobile;
    // console.log(isMobile);
    const noPrint = true;
    const noCopy = true;
    const noScreenshot = true;
    const autoBlur = true;
    if (noCopy) {
      document.body.oncopy = function () {
        return false;
      };
      document.body.oncontextmenu = function () {
        return false;
      };
      document.body.onselectstart = document.body.ondrag = function () {
        return false;
      };
      document.onkeydown = function () {
        if (
          (event.ctrlKey == true || event.metaKey == true) &&
          event.keyCode == 83
        ) {
          event.preventDefault();
        }
        if (
          (event.ctrlKey == true || event.metaKey == true) &&
          event.code == 83
        ) {
          event.preventDefault();
        }
      };
    }

    if (noPrint) {
      const c = document.createElement('span');
      c.style.display = 'none';
      c.style.postion = 'absolute';
      c.style.background = '#000';
      const first = document.body.firstChild;
      const wraphtml = document.body.insertBefore(c, first);
      c.setAttribute('width', document.body.scrollWidth);
      c.setAttribute('height', document.body.scrollHeight);
      c.style.display = 'block';
      const cssNode3 = document.createElement('style');
      cssNode3.type = 'text/css';
      cssNode3.media = 'print';
      cssNode3.innerHTML = 'body{display:none}';
      document.head.appendChild(cssNode3);
    }

    const cssNode2 = document.createElement('style');
    cssNode2.type = 'text/css';
    cssNode2.media = 'screen';
    cssNode2.innerHTML =
      'div{-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}';
    document.head.appendChild(cssNode2);
    document.body.style.cssText =
      '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;';

    function toBlur() {
      // eslint-disable-next-line no-undef
      if (autoBlur) {
        if (!isMobile) {
          render.style.cssText =
            '-webkit-filter: blur(20px);-moz-filter: blur(20px);-ms-filter: blur(20px);-o-filter: blur(20px);filter: blur(20px);';
        } else
          render.style.cssText =
            '-webkit-filter: brightness(0.01);-moz-filter: brightness(0.01);-ms-filter: brightness(0.01);-o-filter: brightness(0.01);filter: brightness(0.01);';
      }
    }

    function toClear() {
      render.style.cssText =
        '-webkit-filter: blur(0px);-moz-filter: blur(0px);-ms-filter: blur(0px);-o-filter: blur(0px);filter: blur(0px);';
    }

    document.onclick = function (event) {
      toast.dismiss();
      toClear();
    };

    document.onmouseleave = function (event) {
      toBlur();
      toast.loading('Volte à página para continuar.');
    };

    document.onblur = function (event) {
      toBlur();
    };

    document.addEventListener('keyup', (e) => {
      if (e.key == 'PrintScreen') {
        if (noScreenshot) {
          navigator.clipboard.writeText('');
        }
      }
    });

    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && e.key == 'p') {
        if (noPrint) {
          e.cancelBubble = true;
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      }
    });
  }
  return (
    <ImageRenderWrapper>
      <ImageRenderWrapper id="image-render">
        <Locker isPaid={isPaid} />

        <div className="imageToRender">
          <img crossOrigin="" alt="" src={userImage} className="userImage" />
          <img
            crossOrigin=""
            alt=""
            src={placeholder}
            className="placeholder"
          />
          <img crossOrigin="" src={userBkg} alt="" />
        </div>
        <p className="nameUser">{newName}</p>
        <p className="dateUser">{newDate}</p>
        <div className="country">
          <p>{newCountry}</p>
          {/* <img src={countryImg} alt="" className="country-img"/> */}

          {(() => {
            if (!newCountry) {
              return '';
            }
            if (newCountry) {
              return (
                <img src={userCountryImg} alt="" className="country-img" />
              );
            }
          })()}
        </div>
        <p className="heightUser">{newHeight}</p>
        <p className="weightUser">{newWeight}</p>
        <div className="positionWrapper">
          {(() => {
            if (!newPosition) {
              return '';
            }
            if (newPosition === 'ataque') {
              return (
                <img
                  src={positionAttack}
                  className="positionBackground"
                  alt=""
                />
              );
            }
            if (newPosition === 'centro') {
              return (
                <img
                  src={positionCenter}
                  className="positionBackground"
                  alt=""
                />
              );
            }
            if (newPosition === 'defesa') {
              return (
                <img
                  src={positionDefense}
                  className="positionBackground"
                  alt=""
                />
              );
            }
            if (newPosition === 'goleiro') {
              return (
                <img src={positionGoal} className="positionBackground" alt="" />
              );
            }
          })()}
        </div>
      </ImageRenderWrapper>
    </ImageRenderWrapper>
  );
}
