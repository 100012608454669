import styled from 'styled-components';
import baseBkg from '../../images/Page-1.png';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: url(${baseBkg}),
    linear-gradient(180deg, #9b1031 0%, rgba(39, 0, 21, 1) 100%);
  font-size: 30px;
  z-index: 9000;
  border-top: 15px solid #700a27;
  pointer-events: none;

  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 752.9360961914062px;
      stroke-dasharray: 752.9360961914062px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 752.9360961914062px;
    }
  }

  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(255, 255, 255);
    }
  }

  .svg-elem-1 {
    animation: animate-svg-stroke-1 infinite alternate-reverse 2s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
      animate-svg-fill-1 infinite alternate-reverse 2s
        cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }

  svg {
    position: absolute;
    z-index: 9999;
    max-width: 80%;
  }
`;
