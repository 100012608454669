import React, { createContext, useContext, useState } from 'react';

export const PictureContext = createContext({});

export function PictureProvider(props) {
  const [picture, setPicture] = useState(false);

  return (
    <PictureContext.Provider value={{ picture, setPicture }}>
      {props.children}
    </PictureContext.Provider>
  );
}

export const usePicture = () => useContext(PictureContext);
