import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';

export default function Loading({ isLoading }) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isLoading) return <></>;
  return (
    <Container>
      <svg
        id="Grupo_769"
        data-name="Grupo 769"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="392.848"
        height="145.243"
        viewBox="0 0 392.848 145.243"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Retângulo_411"
              data-name="Retângulo 411"
              width="392.848"
              height="145.243"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Grupo_768"
          data-name="Grupo 768"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Caminho_6070"
            data-name="Caminho 6070"
            d="M105.024,63.212V21.973a4.2,4.2,0,0,0-4.195-4.195H98.781l-.086-.634a4.2,4.2,0,0,0-4.718-3.573L63.327,17.778H32.3A4.209,4.209,0,0,0,28.1,21.973v.634l-2.025.281a4.179,4.179,0,0,0-2.768,1.622,4.126,4.126,0,0,0-.817,3.11L27.136,61.4,8.925,79.609a19.524,19.524,0,0,0-5.757,13.9v27.746a35.682,35.682,0,0,0,4.741,18.705,2.186,2.186,0,0,0,3.642-2.419,32.008,32.008,0,0,1-4.01-16.286V93.509A15.187,15.187,0,0,1,12.016,82.7L27.883,66.835l.22,1.608V84.532L16.922,95.82l-.037.037A2.187,2.187,0,1,0,20.026,98.9L39.164,79.58a6.884,6.884,0,0,1,4.911-2.055h.017a6.887,6.887,0,0,1,4.9,2.032l.162.163a7.177,7.177,0,0,1,2.129,5.12,7.065,7.065,0,0,1-2.107,5.032c-7.658,7.586-19.133,19.061-19.133,19.061a2.187,2.187,0,0,0,1.546,3.732h.059A13.012,13.012,0,0,1,44.712,125.63a2.187,2.187,0,0,0,4.374,0,17.326,17.326,0,0,0-2.246-8.5l17.482-2.4a26.268,26.268,0,0,1-5.857,15.921,2.21,2.21,0,0,0-.633,1.538v6.559a2.186,2.186,0,1,0,4.373,0v-5.731a31.608,31.608,0,0,0,6.449-18.32s-.011-.221-.024-.558l1.287-.177h30.912a4.2,4.2,0,0,0,4.195-4.195v-.622l2.037-.281a4.219,4.219,0,0,0,3.586-4.731ZM92.317,107.8l-.427.061H40.824v-3.512c3.637-3.628,7.927-7.9,11.432-11.374.021-.02.042-.041.062-.062a11.507,11.507,0,0,0,2.891-11.281,18.6,18.6,0,0,1,4.515-.573H73.418a18.923,18.923,0,0,1,18.9,18.9ZM52.762,61.15a13.809,13.809,0,1,1,13.8,13.816,13.81,13.81,0,0,1-13.8-13.816m46.159,46.714H98.41v-7.9A25.022,25.022,0,0,0,79.974,75.837,19.906,19.906,0,1,0,51.851,74.53q.623.684,1.308,1.308c-.443.123-.879.261-1.313.407a11.224,11.224,0,0,0-7.754-3.093h-.026A11.228,11.228,0,0,0,36.058,76.5l-1.865,1.883V23.874H98.922Z"
            transform="translate(1.005 4.308)"
            fill="#fff"
            className="svg-elem-1"
          />
          <path
            id="Caminho_6071"
            data-name="Caminho 6071"
            d="M16.92,23.372a2.186,2.186,0,0,0-1.782-2.527L2.559,18.765l-.03-.005a2.187,2.187,0,0,0-.683,4.32l12.579,2.08a2.185,2.185,0,0,0,2.5-1.788"
            transform="translate(0 5.963)"
            fill="#fff"
            className="svg-elem-1"
          />
          <path
            id="Caminho_6072"
            data-name="Caminho 6072"
            d="M22.83,15.1l.008.03a2.186,2.186,0,1,0,4.234-1.09L23.981,1.672l-.008-.03a2.187,2.187,0,0,0-4.236,1.09Z"
            transform="translate(6.261 0)"
            fill="#fff"
            className="svg-elem-1"
          />
          <path
            id="Caminho_6073"
            data-name="Caminho 6073"
            d="M16.9,19.371A2.186,2.186,0,1,0,20,16.278L10.72,7a2.186,2.186,0,1,0-3.093,3.091Z"
            transform="translate(2.224 2.025)"
            fill="#fff"
            className="svg-elem-1"
          />
          <path
            id="Caminho_6074"
            data-name="Caminho 6074"
            d="M137.03,36.807a2.052,2.052,0,0,1,1.744-1.139,1.563,1.563,0,0,1,1.6.907,7.58,7.58,0,0,1,.4,2.829V49.477h5.7V38.095A9.976,9.976,0,0,0,146,34.8a5.615,5.615,0,0,0-1.21-2.064,4.274,4.274,0,0,0-1.691-1.086,5.888,5.888,0,0,0-1.9-.32,5.658,5.658,0,0,0-3.47,1.157,9.151,9.151,0,0,0-2.687,4.254q-.038-1.181-.161-2.373t-.3-2.687h-5.553q.109,1.6.232,3.061t.124,3.346v11.39h5.7q0-2.741.2-5.227a31.683,31.683,0,0,1,.622-4.4,9.865,9.865,0,0,1,1.134-3.047"
            transform="translate(41.07 9.972)"
            fill="#fff"
          />
          <path
            id="Caminho_6075"
            data-name="Caminho 6075"
            d="M166.268,65.625a11.637,11.637,0,0,0-13.882,0,8.871,8.871,0,0,0-2.563,6.958,8.881,8.881,0,0,0,2.563,6.941,11.568,11.568,0,0,0,13.882,0,8.95,8.95,0,0,0,2.527-6.941,8.936,8.936,0,0,0-2.527-6.958m-4.162,10.553a3.566,3.566,0,0,1-5.537,0,7.568,7.568,0,0,1,0-7.19,3.566,3.566,0,0,1,5.537,0,7.707,7.707,0,0,1,0,7.19"
            transform="translate(47.681 20.153)"
            fill="#fff"
          />
          <path
            id="Caminho_6076"
            data-name="Caminho 6076"
            d="M149.821,78.309q-.89.089-1.815.142t-1.78.053a5.757,5.757,0,0,1-4.716-1.922,8.715,8.715,0,0,1-1.582-5.659,8.715,8.715,0,0,1,1.582-5.659,5.757,5.757,0,0,1,4.716-1.922c.45,0,.954.017,1.512.053s1.11.083,1.656.142,1.056.131,1.531.214.854.161,1.139.232a7.445,7.445,0,0,0,.264-1q.125-.605.214-1.281t.142-1.353q.053-.676.054-1.175c-.435-.109-.875-.2-1.318-.264q-.785-.125-1.656-.232t-1.78-.161q-.908-.055-1.761-.054a16.7,16.7,0,0,0-5.18.747,9.948,9.948,0,0,0-3.9,2.3,10.131,10.131,0,0,0-2.456,3.9,18.229,18.229,0,0,0,0,11.034,10.152,10.152,0,0,0,2.463,3.894,9.938,9.938,0,0,0,3.9,2.3,16.7,16.7,0,0,0,5.18.747q.71,0,1.548-.054t1.637-.16q.8-.109,1.495-.232a9.915,9.915,0,0,0,1.156-.264,7.449,7.449,0,0,0,.264-1q.125-.605.214-1.3T152.686,79q.053-.676.054-1.139a11.16,11.16,0,0,1-1.281.249q-.747.107-1.637.2"
            transform="translate(42.599 18.609)"
            fill="#fff"
          />
          <path
            id="Caminho_6077"
            data-name="Caminho 6077"
            d="M151.415,46.056a31.491,31.491,0,0,1,.622-4.4,9.856,9.856,0,0,1,1.135-3.047,2.052,2.052,0,0,1,1.744-1.139,1.563,1.563,0,0,1,1.6.907,7.581,7.581,0,0,1,.4,2.829V51.283h5.7V39.9a9.975,9.975,0,0,0-.463-3.3,5.615,5.615,0,0,0-1.21-2.064,4.274,4.274,0,0,0-1.691-1.086,5.889,5.889,0,0,0-1.9-.32,6.249,6.249,0,0,0-1.832.264,4.991,4.991,0,0,0-1.637.871,6.528,6.528,0,0,0-1.424,1.637,13.587,13.587,0,0,0-1.229,2.563V25.655h-5.7V51.283h5.7q0-2.741.2-5.227"
            transform="translate(46.321 8.166)"
            fill="#fff"
          />
          <path
            id="Caminho_6078"
            data-name="Caminho 6078"
            d="M119.065,33.6V50.939h5.7v-24.2h-4.912a10.827,10.827,0,0,0-2.439.231,3.865,3.865,0,0,0-1.6.747,3.645,3.645,0,0,0-1,1.37,10.28,10.28,0,0,0-.624,2.136L111.3,42.4h-.178l-3.062-11.178a8.937,8.937,0,0,0-.871-2.42,3.986,3.986,0,0,0-1.21-1.353,3.782,3.782,0,0,0-1.619-.587,15.754,15.754,0,0,0-2.1-.124H97.6l.005,24.2h5.405V33.6h.142l4.77,17.334h6.266l4.7-17.334Z"
            transform="translate(31.066 8.51)"
            fill="#fff"
          />
          <rect
            id="Retângulo_408"
            data-name="Retângulo 408"
            width="5.695"
            height="17.797"
            transform="translate(160.486 41.652)"
            fill="#fff"
          />
          <path
            id="Caminho_6079"
            data-name="Caminho 6079"
            d="M123.1,63.325a15.737,15.737,0,0,0-4.271.551,8.6,8.6,0,0,0-3.384,1.8,8.3,8.3,0,0,0-2.225,3.275,13.618,13.618,0,0,0-.8,4.983h0a13.672,13.672,0,0,0,.356,3.24,7.455,7.455,0,0,0,1.068,2.492,5.1,5.1,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.3,6.3,0,0,0,1.78-.249,4.42,4.42,0,0,0,1.6-.873,7.216,7.216,0,0,0,1.411-1.643,13.326,13.326,0,0,0,1.227-2.5v4.912h5.7v-17.8q-1.675-.142-3.3-.249t-3.258-.107m.711,8.507a20.222,20.222,0,0,1-.89,3.044,5.818,5.818,0,0,1-1.157,1.939,2.052,2.052,0,0,1-1.512.676,1.3,1.3,0,0,1-.968-.446,3.66,3.66,0,0,1-.729-1.227,8.12,8.12,0,0,1-.444-1.8,15.478,15.478,0,0,1-.142-2.154,3.718,3.718,0,0,1,4.415-4.2c.352,0,.715.017,1.085.053s.722.078,1.055.125q-.316,2.207-.712,3.987"
            transform="translate(35.783 20.156)"
            fill="#fff"
          />
          <path
            id="Caminho_6080"
            data-name="Caminho 6080"
            d="M124.739,25.357a21.142,21.142,0,0,0-1.815,1.548,17.834,17.834,0,0,0-1.673,1.8,16.777,16.777,0,0,0,1.693,1.8q.979.908,1.8,1.549a17.278,17.278,0,0,0,1.78-1.549q.961-.943,1.673-1.8a24.16,24.16,0,0,0-1.656-1.78,18.3,18.3,0,0,0-1.8-1.566"
            transform="translate(38.595 8.071)"
            fill="#fff"
          />
          <path
            id="Caminho_6081"
            data-name="Caminho 6081"
            d="M108.242,65.13h-.854a15.671,15.671,0,0,0-4.27.552,8.585,8.585,0,0,0-3.381,1.8,8.3,8.3,0,0,0-2.225,3.275,15.5,15.5,0,0,0-.446,8.222,7.455,7.455,0,0,0,1.068,2.492,5.1,5.1,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.3,6.3,0,0,0,1.78-.249,4.42,4.42,0,0,0,1.6-.873,7.2,7.2,0,0,0,1.407-1.643,13.327,13.327,0,0,0,1.227-2.5v4.912h5.7V57.655h-5.7Zm-.142,8.507a20.22,20.22,0,0,1-.89,3.044,5.836,5.836,0,0,1-1.156,1.939,2.052,2.052,0,0,1-1.513.676,1.3,1.3,0,0,1-.961-.444,3.638,3.638,0,0,1-.729-1.227,8.123,8.123,0,0,1-.446-1.8,15.478,15.478,0,0,1-.142-2.154,3.712,3.712,0,0,1,4.41-4.2c.352,0,.715.017,1.085.053s.722.078,1.055.125q-.316,2.207-.712,3.987"
            transform="translate(30.781 18.352)"
            fill="#fff"
          />
          <rect
            id="Retângulo_409"
            data-name="Retângulo 409"
            width="5.695"
            height="17.797"
            transform="translate(323.686 41.652)"
            fill="#fff"
          />
          <path
            id="Caminho_6082"
            data-name="Caminho 6082"
            d="M248.534,25.357a21.144,21.144,0,0,0-1.815,1.548,17.83,17.83,0,0,0-1.673,1.8,16.741,16.741,0,0,0,1.689,1.8q.979.908,1.8,1.549a17.139,17.139,0,0,0,1.78-1.549q.961-.943,1.673-1.8a23.564,23.564,0,0,0-1.654-1.78,18.283,18.283,0,0,0-1.8-1.566"
            transform="translate(77.999 8.071)"
            fill="#fff"
          />
          <path
            id="Caminho_6083"
            data-name="Caminho 6083"
            d="M246.449,74.8a21.826,21.826,0,0,0,3.756-3.044,9.736,9.736,0,0,0,2.064-3.08,8.6,8.6,0,0,0,.624-3.2,7.073,7.073,0,0,0-.527-2.741,5.95,5.95,0,0,0-1.62-2.225,7.981,7.981,0,0,0-2.724-1.495,12.062,12.062,0,0,0-3.844-.551,24.112,24.112,0,0,0-3.809.339,23.073,23.073,0,0,0-4.093,1.055q0,.5.054,1.175t.16,1.371q.107.7.232,1.318a7.315,7.315,0,0,0,.264,1.015,21.742,21.742,0,0,1,3.327-1.032,13.936,13.936,0,0,1,2.973-.356,4.236,4.236,0,0,1,2.637.783,2.841,2.841,0,0,1,1.032,2.42,5.186,5.186,0,0,1-.552,2.278,10.338,10.338,0,0,1-1.834,2.492,36.109,36.109,0,0,1-3.381,3.061q-2.1,1.709-5.161,4.022l0,4.631H253.39V77.892H240.718a42.955,42.955,0,0,0,5.731-3.1"
            transform="translate(75.128 18.606)"
            fill="#fff"
          />
          <path
            id="Caminho_6084"
            data-name="Caminho 6084"
            d="M239.725,49.479V46.737a29.368,29.368,0,0,1,.446-4.841,7.071,7.071,0,0,1,1.21-2.973,4.338,4.338,0,0,1,2.241-1.513,12.55,12.55,0,0,1,3.578-.427q0-1.424-.107-2.936a26.254,26.254,0,0,0-.32-2.724,5.828,5.828,0,0,0-3.823,1.62,12.879,12.879,0,0,0-3.222,4.859q0-1.495-.124-2.9t-.373-3.221h-5.549q.1,1.6.224,3.061t.124,3.346v11.39Z"
            transform="translate(74.382 9.97)"
            fill="#fff"
          />
          <path
            id="Caminho_6085"
            data-name="Caminho 6085"
            d="M298.861,31.432q-1.62-.107-3.256-.107a15.686,15.686,0,0,0-4.271.552,8.608,8.608,0,0,0-3.381,1.8,8.3,8.3,0,0,0-2.225,3.275,13.619,13.619,0,0,0-.8,4.983,13.7,13.7,0,0,0,.359,3.235,7.456,7.456,0,0,0,1.068,2.492,5.1,5.1,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.3,6.3,0,0,0,1.78-.249,4.42,4.42,0,0,0,1.6-.873,7.229,7.229,0,0,0,1.405-1.637,13.414,13.414,0,0,0,1.229-2.5v4.912h5.7v-17.8q-1.677-.142-3.3-.249m-2.544,8.4a20.228,20.228,0,0,1-.89,3.044,5.818,5.818,0,0,1-1.158,1.939,2.054,2.054,0,0,1-1.512.676,1.3,1.3,0,0,1-.965-.446,3.639,3.639,0,0,1-.729-1.227,8.122,8.122,0,0,1-.446-1.8,15.473,15.473,0,0,1-.142-2.154,4.5,4.5,0,0,1,.978-3.114,4.484,4.484,0,0,1,3.434-1.085q.53,0,1.086.053t1.055.125q-.316,2.207-.712,3.987"
            transform="translate(90.692 9.971)"
            fill="#fff"
          />
          <path
            id="Caminho_6086"
            data-name="Caminho 6086"
            d="M236.078,61.683a8.642,8.642,0,0,0-3.239-2.4,11.645,11.645,0,0,0-8.578,0,8.642,8.642,0,0,0-3.239,2.4,10.963,10.963,0,0,0-2.047,3.9,18.044,18.044,0,0,0-.712,5.3v0a18.369,18.369,0,0,0,.712,5.352,10.964,10.964,0,0,0,2.047,3.915,8.656,8.656,0,0,0,3.239,2.4,11.645,11.645,0,0,0,8.578,0,8.656,8.656,0,0,0,3.239-2.4,10.963,10.963,0,0,0,2.047-3.915,18.4,18.4,0,0,0,.712-5.356,18.044,18.044,0,0,0-.712-5.3,10.983,10.983,0,0,0-2.047-3.9m-3.544,12.358a8.069,8.069,0,0,1-.712,2.4,3.869,3.869,0,0,1-1.3,1.531,3.488,3.488,0,0,1-1.977.527l0,0a3.484,3.484,0,0,1-1.976-.531,3.864,3.864,0,0,1-1.3-1.531,8.069,8.069,0,0,1-.712-2.4,20.982,20.982,0,0,1-.214-3.149,13.292,13.292,0,0,1,.943-5.517,3.628,3.628,0,0,1,6.514,0,13.313,13.313,0,0,1,.943,5.517,20.982,20.982,0,0,1-.214,3.149"
            transform="translate(69.473 18.608)"
            fill="#fff"
          />
          <path
            id="Caminho_6087"
            data-name="Caminho 6087"
            d="M284.733,34.54a4.26,4.26,0,0,0-1.691-1.086,5.888,5.888,0,0,0-1.9-.32,6.214,6.214,0,0,0-1.834.264,4.966,4.966,0,0,0-1.637.871,6.528,6.528,0,0,0-1.424,1.637,13.585,13.585,0,0,0-1.229,2.563V25.655h-5.7V51.283l5.692,0q0-2.741.2-5.232a31.494,31.494,0,0,1,.624-4.4,9.836,9.836,0,0,1,1.139-3.044,2.052,2.052,0,0,1,1.744-1.139,1.563,1.563,0,0,1,1.6.907,7.607,7.607,0,0,1,.4,2.829V51.283h5.7V39.9a9.942,9.942,0,0,0-.463-3.3,5.6,5.6,0,0,0-1.21-2.064"
            transform="translate(85.726 8.166)"
            fill="#fff"
          />
          <path
            id="Caminho_6088"
            data-name="Caminho 6088"
            d="M268.59,32.735a4.26,4.26,0,0,0-1.691-1.086,5.89,5.89,0,0,0-1.9-.32,5.658,5.658,0,0,0-3.47,1.157,9.161,9.161,0,0,0-2.687,4.254q-.038-1.181-.161-2.373t-.3-2.687h-5.553q.109,1.6.232,3.061t.124,3.346v11.39h5.7q0-2.741.191-5.23a31.489,31.489,0,0,1,.624-4.4,9.836,9.836,0,0,1,1.139-3.044,2.052,2.052,0,0,1,1.744-1.139,1.563,1.563,0,0,1,1.6.907,7.607,7.607,0,0,1,.4,2.829V49.477h5.7V38.095a9.942,9.942,0,0,0-.463-3.3,5.6,5.6,0,0,0-1.21-2.064"
            transform="translate(80.475 9.972)"
            fill="#fff"
          />
          <path
            id="Caminho_6089"
            data-name="Caminho 6089"
            d="M261.349,74.8a21.825,21.825,0,0,0,3.756-3.044,9.737,9.737,0,0,0,2.064-3.08,8.6,8.6,0,0,0,.624-3.2,7.025,7.025,0,0,0-.527-2.741,5.949,5.949,0,0,0-1.62-2.225,7.981,7.981,0,0,0-2.724-1.495,12.063,12.063,0,0,0-3.844-.551,24.112,24.112,0,0,0-3.809.339,23.073,23.073,0,0,0-4.093,1.055q0,.5.054,1.175t.16,1.371q.107.7.232,1.318a7.315,7.315,0,0,0,.264,1.015,21.757,21.757,0,0,1,3.329-1.032,13.914,13.914,0,0,1,2.971-.356,4.236,4.236,0,0,1,2.637.783,2.841,2.841,0,0,1,1.032,2.42,5.185,5.185,0,0,1-.552,2.278,10.337,10.337,0,0,1-1.834,2.492,36.1,36.1,0,0,1-3.381,3.061q-2.1,1.709-5.161,4.022l.009,4.631H268.29V77.892H255.618a42.87,42.87,0,0,0,5.731-3.1"
            transform="translate(79.871 18.606)"
            fill="#fff"
          />
          <path
            id="Caminho_6090"
            data-name="Caminho 6090"
            d="M192.626,63.325a15.737,15.737,0,0,0-4.271.551,8.611,8.611,0,0,0-3.381,1.8,8.3,8.3,0,0,0-2.225,3.275,15.45,15.45,0,0,0-.442,8.218,7.455,7.455,0,0,0,1.068,2.492,5.1,5.1,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.3,6.3,0,0,0,1.78-.249,4.42,4.42,0,0,0,1.6-.873,7.228,7.228,0,0,0,1.405-1.637,13.412,13.412,0,0,0,1.229-2.5v4.912h5.7v-17.8q-1.677-.142-3.3-.249t-3.256-.107m.711,8.507a20.22,20.22,0,0,1-.89,3.044,5.836,5.836,0,0,1-1.156,1.939,2.054,2.054,0,0,1-1.512.676,1.3,1.3,0,0,1-.968-.446,3.638,3.638,0,0,1-.729-1.227,8.122,8.122,0,0,1-.446-1.8,15.478,15.478,0,0,1-.142-2.154,3.718,3.718,0,0,1,4.414-4.2c.353,0,.716.017,1.086.053s.722.078,1.055.125q-.316,2.207-.712,3.987"
            transform="translate(57.913 20.156)"
            fill="#fff"
          />
          <path
            id="Caminho_6091"
            data-name="Caminho 6091"
            d="M170.032,48.712a7.26,7.26,0,0,0,1.408-1.641,13.413,13.413,0,0,0,1.229-2.5v4.912h5.694v-17.8q-1.675-.142-3.3-.249t-3.258-.107a15.686,15.686,0,0,0-4.271.552,8.608,8.608,0,0,0-3.381,1.8,8.3,8.3,0,0,0-2.225,3.275,13.618,13.618,0,0,0-.8,4.983,13.664,13.664,0,0,0,.359,3.239,7.455,7.455,0,0,0,1.068,2.492,5.1,5.1,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.349,6.349,0,0,0,1.78-.249,4.44,4.44,0,0,0,1.6-.873m-2.761-4.892a8.122,8.122,0,0,1-.446-1.8,15.493,15.493,0,0,1-.142-2.154,4.5,4.5,0,0,1,.973-3.115,4.479,4.479,0,0,1,3.434-1.085c.353,0,.716.017,1.086.053s.722.078,1.055.125q-.316,2.207-.712,3.987a20.219,20.219,0,0,1-.89,3.044,5.836,5.836,0,0,1-1.156,1.939,2.054,2.054,0,0,1-1.512.676,1.3,1.3,0,0,1-.961-.444,3.638,3.638,0,0,1-.729-1.227"
            transform="translate(51.287 9.971)"
            fill="#fff"
          />
          <path
            id="Caminho_6092"
            data-name="Caminho 6092"
            d="M184.365,51.37h5.7V37.7q1.671.142,2.9.178t2.474.036c.119-.4.225-.778.32-1.122s.178-.682.249-1.014a9.429,9.429,0,0,0,.16-1.032q.053-.534.053-1.175-1.608,0-3.026.088t-3.132.339v-2.1q0-2.171,2.278-2.171a9.475,9.475,0,0,1,1.958.2,15.245,15.245,0,0,1,1.673.444c.095-.26.19-.575.285-.943s.178-.758.249-1.157.131-.795.178-1.186a8.8,8.8,0,0,0,.071-1.055,17.549,17.549,0,0,0-2.064-.446,16.328,16.328,0,0,0-2.6-.2,12.84,12.84,0,0,0-3.008.339,6.836,6.836,0,0,0-2.456,1.1,5.3,5.3,0,0,0-1.656,2.012,6.986,6.986,0,0,0-.605,3.061v1.673h-2.527c-.047.283-.095.61-.142.978s-.083.747-.107,1.139-.041.78-.053,1.175-.018.746-.018,1.055h2.85Z"
            transform="translate(57.777 8.079)"
            fill="#fff"
          />
          <rect
            id="Retângulo_410"
            data-name="Retângulo 410"
            width="5.695"
            height="17.797"
            transform="translate(256.342 41.652)"
            fill="#fff"
          />
          <path
            id="Caminho_6093"
            data-name="Caminho 6093"
            d="M182.748,65.5a5.139,5.139,0,0,0-1.727-1.6,4.758,4.758,0,0,0-2.373-.57,5.946,5.946,0,0,0-1.78.264,4.648,4.648,0,0,0-1.6.89,7.572,7.572,0,0,0-1.424,1.637,12.347,12.347,0,0,0-1.246,2.546q-.071-1.139-.178-2.331t-.285-2.651h-5.553q.109,1.6.233,3.061t.125,3.346V89.3h5.7V81.83h.854a15.686,15.686,0,0,0,4.271-.552,8.594,8.594,0,0,0,3.381-1.8,8.31,8.31,0,0,0,2.224-3.275,15.428,15.428,0,0,0,.45-8.216,7.455,7.455,0,0,0-1.068-2.492m-5.112,10.909a4.484,4.484,0,0,1-3.434,1.085c-.349,0-.711-.022-1.081-.058s-.722-.076-1.055-.124q.316-2.207.712-4.005a19.463,19.463,0,0,1,.89-3.037,5.857,5.857,0,0,1,1.157-1.922,2.054,2.054,0,0,1,1.512-.676,1.3,1.3,0,0,1,.961.444,3.663,3.663,0,0,1,.73,1.227,8.118,8.118,0,0,1,.444,1.8,15.477,15.477,0,0,1,.142,2.154,4.5,4.5,0,0,1-.98,3.114"
            transform="translate(53.024 20.157)"
            fill="#fff"
          />
          <path
            id="Caminho_6094"
            data-name="Caminho 6094"
            d="M213.351,74.807a21.818,21.818,0,0,0,3.755-3.044,9.707,9.707,0,0,0,2.058-3.087,8.6,8.6,0,0,0,.624-3.2,7.078,7.078,0,0,0-.527-2.741,5.949,5.949,0,0,0-1.62-2.225,8,8,0,0,0-2.724-1.495,12.063,12.063,0,0,0-3.844-.551,24.113,24.113,0,0,0-3.809.339,23,23,0,0,0-4.087,1.055c0,.332.017.724.053,1.175s.088.907.159,1.371.149.9.232,1.318a7.263,7.263,0,0,0,.264,1.015,21.74,21.74,0,0,1,3.327-1.032,13.937,13.937,0,0,1,2.973-.356,4.236,4.236,0,0,1,2.637.783,2.845,2.845,0,0,1,1.032,2.42,5.182,5.182,0,0,1-.552,2.278,10.333,10.333,0,0,1-1.832,2.492,36.107,36.107,0,0,1-3.381,3.061q-2.1,1.709-5.161,4.022v4.627h17.366V77.9H207.62a42.722,42.722,0,0,0,5.731-3.1"
            transform="translate(64.592 18.607)"
            fill="#fff"
          />
          <path
            id="Caminho_6095"
            data-name="Caminho 6095"
            d="M197.45,25.357a21.135,21.135,0,0,0-1.815,1.548,17.829,17.829,0,0,0-1.673,1.8,16.774,16.774,0,0,0,1.693,1.8q.979.908,1.8,1.549a17.279,17.279,0,0,0,1.78-1.549q.961-.943,1.673-1.8a24.153,24.153,0,0,0-1.656-1.78,18.3,18.3,0,0,0-1.8-1.566"
            transform="translate(61.739 8.071)"
            fill="#fff"
          />
          <path
            id="Caminho_6096"
            data-name="Caminho 6096"
            d="M221.086,49.425a5.888,5.888,0,0,0,1.9.32,6.258,6.258,0,0,0,1.834-.264,4.994,4.994,0,0,0,1.637-.873,6.528,6.528,0,0,0,1.424-1.637,13.587,13.587,0,0,0,1.229-2.563v4.983h5.7V31.6h-5.691q0,2.709-.2,5.214a31.73,31.73,0,0,1-.624,4.414,9.849,9.849,0,0,1-1.139,3.044,2.052,2.052,0,0,1-1.744,1.139,1.565,1.565,0,0,1-1.6-.907,7.588,7.588,0,0,1-.4-2.83V31.6h-5.7V42.98a9.99,9.99,0,0,0,.463,3.275,5.589,5.589,0,0,0,1.21,2.083,4.263,4.263,0,0,0,1.69,1.088"
            transform="translate(69.301 10.057)"
            fill="#fff"
          />
          <path
            id="Caminho_6097"
            data-name="Caminho 6097"
            d="M207.9,57.665q5.659,0,8.187-2.474a9.538,9.538,0,0,0,2.527-7.136V31.681q-1.677-.142-3.3-.249t-3.256-.107a15.687,15.687,0,0,0-4.271.552,8.608,8.608,0,0,0-3.381,1.8,8.3,8.3,0,0,0-2.225,3.273,13.618,13.618,0,0,0-.8,4.983h0a13.7,13.7,0,0,0,.359,3.235,7.455,7.455,0,0,0,1.068,2.492,5.09,5.09,0,0,0,1.744,1.6,4.785,4.785,0,0,0,2.349.57,6.3,6.3,0,0,0,1.78-.249,4.431,4.431,0,0,0,1.6-.873,7.229,7.229,0,0,0,1.405-1.637,13.412,13.412,0,0,0,1.229-2.5v3.487a10.255,10.255,0,0,1-.178,1.9,3.851,3.851,0,0,1-.765,1.691,4.094,4.094,0,0,1-1.727,1.21,8.2,8.2,0,0,1-3.026.463c-.309,0-.671-.005-1.086-.017s-.837-.036-1.264-.071l-1.246-.107q-.605-.053-1.1-.125,0,.995.107,2.153a11.506,11.506,0,0,0,.32,1.941,8.253,8.253,0,0,0,2.012.41,25.733,25.733,0,0,0,2.937.16m4.872-17.833a20.217,20.217,0,0,1-.89,3.044,5.836,5.836,0,0,1-1.156,1.939,2.054,2.054,0,0,1-1.512.676,1.3,1.3,0,0,1-.961-.446,3.638,3.638,0,0,1-.729-1.227,8.122,8.122,0,0,1-.446-1.8,15.467,15.467,0,0,1-.142-2.153,4.5,4.5,0,0,1,.973-3.115,4.484,4.484,0,0,1,3.434-1.085c.353,0,.716.017,1.086.053s.722.078,1.055.125q-.316,2.207-.712,3.987"
            transform="translate(64.101 9.971)"
            fill="#fff"
          />
        </g>
      </svg>
    </Container>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};
