/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import { FaHome, FaSignInAlt, FaUserAlt } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import { Advice, HeaderTop, Title } from './styled';
import headerAdvice from '../../images/header-advice.png';
import headerTop from '../../images/header-top.svg';
import { useIsPaid } from '../../providers/paid';

export default function Header() {
  // const botaoClicado = useSelector((state) => state.example.botaoClicado);
  // console.log(botaoClicado);
  const { isPaid } = useIsPaid();
  if (!isPaid) {
    return (
      <>
        <HeaderTop>
          <Link to="/">
            <img src={headerTop} alt="" />
          </Link>
          <Link to="/about">
            <Title>CONTATO</Title>
          </Link>
        </HeaderTop>
        <Advice src={headerAdvice} alt="" className="advice" />
      </>
    );
  }
  return (
    <HeaderTop>
      <img src={headerTop} alt="" />
    </HeaderTop>
  );
}
