import React from 'react';
import { FooterStyled } from './styled';

import flag1 from '../../images/footer/flag1.png';
import flag2 from '../../images/footer/flag2.png';
import flag3 from '../../images/footer/flag3.png';
import flag4 from '../../images/footer/flag4.png';
import flag5 from '../../images/footer/flag5.png';
import flag6 from '../../images/footer/flag6.png';
import flag7 from '../../images/footer/flag7.png';
import flag8 from '../../images/footer/flag8.png';
import flag9 from '../../images/footer/flag9.png';
import flag10 from '../../images/footer/flag10.png';
import flag11 from '../../images/footer/flag11.png';
import flag12 from '../../images/footer/flag12.png';
import flag13 from '../../images/footer/flag13.png';
import flag14 from '../../images/footer/flag14.png';
import flag15 from '../../images/footer/flag15.png';
import flag16 from '../../images/footer/flag16.png';
import flag17 from '../../images/footer/flag17.png';
import flag18 from '../../images/footer/flag18.png';
import flag19 from '../../images/footer/flag19.png';
import flag20 from '../../images/footer/flag20.png';
import flag21 from '../../images/footer/flag21.png';
import flag22 from '../../images/footer/flag22.png';
import flag23 from '../../images/footer/flag23.png';
import flag24 from '../../images/footer/flag24.png';
import flag25 from '../../images/footer/flag25.png';
import flag26 from '../../images/footer/flag26.png';
import flag27 from '../../images/footer/flag27.png';
import flag28 from '../../images/footer/flag28.png';
import flag29 from '../../images/footer/flag29.png';
import flag30 from '../../images/footer/flag30.png';
import flag31 from '../../images/footer/flag31.png';
import flag32 from '../../images/footer/flag32.png';
import securePay from '../../images/securePay.png';
import { useIsPaid } from '../../providers/paid';

export default function Footer() {
  const { isPaid } = useIsPaid();
  if (!isPaid) {
    return (
      <FooterStyled>
        <img src={securePay} alt="" className="securePay" />
        <div id="logos-home-page">
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag1}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag2}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag3}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag4}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag5}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag6}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag7}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag8}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag9}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag10}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag11}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag12}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag13}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag14}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag15}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag16}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag17}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag18}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag19}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag20}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag21}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag22}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag23}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag24}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag25}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag26}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag27}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag28}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag29}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag30}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag31}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
              <div className="slide">
                <img
                  className="alignnone size-full wp-image-13024"
                  src={flag32}
                  alt=""
                  width="60"
                  height="35"
                />
              </div>
            </div>
          </div>
        </div>
      </FooterStyled>
    );
  }
  return (
    <FooterStyled>
      <div id="logos-home-page">
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag1}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag2}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag3}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag4}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag5}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag6}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag7}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag8}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag9}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag10}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag11}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag12}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag13}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag14}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag15}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag16}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag17}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag18}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag19}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag20}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag21}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag22}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag23}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag24}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag25}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag26}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag27}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag28}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag29}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag30}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag31}
                alt=""
                width="60"
                height="35"
              />
            </div>
            <div className="slide">
              <img
                className="alignnone size-full wp-image-13024"
                src={flag32}
                alt=""
                width="60"
                height="35"
              />
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}
